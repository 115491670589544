import { endpoints } from "./endpoints";
import http from "./http";

export const trySampleText = (setText, setTextLoader,anonymization,setTranslationText) => {
 
  setTextLoader(true);

  const fetchSampleText = async () => {
    try {
    const res = await http().get(
        `${endpoints.forms.trysampletext}?tool_name=${anonymization ? anonymization : "tryToolText"}`
      );
  
     if(res?.meta?.tool_name === "tryToolTextTranslationTool"){
      setText(res?.result?.source_text)
      setTranslationText(res?.result?.translate_text)
     }else{
     
      const data = res.result.text;
  
      setText(data || 'No sample text available');
    }

    } catch (error) {
      console.error("Error fetching sample text:", error);
      setText('Failed to load sample text');
    } finally {
      setTextLoader(false);
    }
  };

  fetchSampleText();
};
