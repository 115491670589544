import React from "react";
// import fileIconSVG from "assets/fileIcon.svg";
import fileIconSVG from 'assets/fileicon.svg'
import moment from "moment";
import { calcFileSize } from "utils/filesizelimit";




const FileLoadingInput = ({ file,divHeight,isGuidance,isLgScreen }) => {
  return (
    <div  style={{
      minHeight: isLgScreen
        ? isGuidance
          ? `183px`
          : divHeight
          ? `${divHeight}px`
          : `230px`
        : "230px",
    }}  className="flex justify-center w-full">
      <div className="py-16 mt-3 flex flex-col items-center">
        <div className="flex p-4 bg-blue98">
          <img src={fileIconSVG} alt="file" />
          <div className="ml-4 font-opensans">
            <p className="text-sm font-medium leading-6 text-grey26">
              {file.name}
            </p>
            <p className="text-xs	text-grey47">
              <span>{calcFileSize(file, "KB", "MB")}</span>
              <span className="mx-1">.</span>
              <span>
                {moment(file.lastModifiedDate).format("DD MMM, YYYY")}
              </span>
            </p>
          </div>
        </div>
        <p className="mt-5 text-grey47 font-primary ">Extracting text...</p>
      </div>
    </div>
  );
};

export default FileLoadingInput;
