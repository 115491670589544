// import React, { useState } from "react";
// import { Tooltip } from "react-tooltip";
// import DownloadIcon from "assets/downloadtool.jpg";

// const DownloadSection = ({
//   handleDownload,
//   selectedFormat,
//   handleFormatChange,
//   downloadOutputFormat,
// }) => {
//   const [open, setOpen] = useState(false);

//   return (
//     <div
//       data-tooltip-id="download-tooltip"
//       data-tooltip-content="Download"
//       className="flex bg-[#F5F5F5] rounded-md  items-center "
//     >
//       <button className="px-3 py-2">
//         <img src={DownloadIcon} alt="/downloadicon" />
//       </button>
//       {/* <select
//         value={selectedFormat}
//         onChange={handleFormatChange}
//         className="!text-[#5B93FF] !outline-none !border-none"
//       >
//         {downloadOutputFormat.map((format, index) => (
//           <option key={index} value={format}>
//             {format}
//           </option>
//         ))}
//       </select> */}
//       <div
//         className="relative   p-2  bg-[#E8E8E8] h-full"
//         onClick={() => setOpen(!open)}
//       >
//         <span
//           className={`text-quotetext-200 arrow ${
//             open ? "rotate-[316deg]" : "rotate-[135deg]"
//           }  transition-all duration-200 ease-linear  `}
//         ></span>
//         {open && (
//           <ul className="absolute text-[12px] text-[#00173A] font-primary font-normal right-0 top-8 bg-white p-4 w-max flex flex-col gap-4">
//             {downloadOutputFormat.map((list, i) => (
//               <li className="flex items-center gap-2" onClick={handleDownload}>
//                 <p className="w-full">{list}</p>
//                 <svg
//                   xmlns="http://www.w3.org/2000/svg"
//                   width="9"
//                   height="14"
//                   viewBox="0 0 9 14"
//                   fill="none"
//                 >
//                   <path
//                     d="M4.5 10V1.5M1 7L4.5 10.5L8 7M1 12.5H8"
//                     stroke="#5B93FF"
//                     stroke-width="1.5"
//                     stroke-linecap="round"
//                     stroke-linejoin="round"
//                   />
//                 </svg>
//               </li>
//             ))}
//           </ul>
//         )}
//       </div>
//       <Tooltip
//         id="download-tooltip"
//         place="top"
//         multiline={true}
//         className="z-50"
//       />
//     </div>
//   );
// };

// export default DownloadSection;
import React, { useState } from "react";
import { Tooltip } from "react-tooltip";
import DownloadIcon from "assets/downloadtool.jpg";

const DownloadSection = ({
  handleDownload,
  selectedFormat,
  handleFormatChange,
  downloadOutputFormat,
  isSource,
  text
}) => {
  const [open, setOpen] = useState(false);

  const handleFormatSelect = (format) => {
    handleFormatChange(format);
    handleDownload(format);
    setOpen(false);
  };

  return (
    <div
      data-tooltip-id="download-tooltip"
      data-tooltip-content="Download"
      style={{ background: "#EEEEEE" }}
      className="flex  bg-[#eceaea] rounded-md items-center"
      onClick={() => setOpen(!open)}
    >
      <button className="px-3 py-2 flex gap-2 items-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="17"
          height="16"
          viewBox="0 0 17 16"
          fill="none"
        >
          <path
            d="M2.72672 15.4289C2.18588 15.4289 1.6672 15.214 1.28477 14.8316C0.902345 14.4492 0.6875 13.9305 0.6875 13.3897V10.4765C0.6875 10.2447 0.779577 10.0224 0.943474 9.85851C1.10737 9.69462 1.32966 9.60254 1.56145 9.60254C1.79324 9.60254 2.01553 9.69462 2.17943 9.85851C2.34332 10.0224 2.4354 10.2447 2.4354 10.4765V13.3897C2.4354 13.5505 2.56591 13.681 2.72672 13.681H14.962C15.0393 13.681 15.1134 13.6503 15.168 13.5956C15.2226 13.541 15.2533 13.4669 15.2533 13.3897V10.4765C15.2533 10.2447 15.3454 10.0224 15.5093 9.85851C15.6732 9.69462 15.8955 9.60254 16.1273 9.60254C16.3591 9.60254 16.5814 9.69462 16.7453 9.85851C16.9091 10.0224 17.0012 10.2447 17.0012 10.4765V13.3897C17.0012 13.9305 16.7864 14.4492 16.404 14.8316C16.0215 15.214 15.5028 15.4289 14.962 15.4289H2.72672Z"
            fill="#5B93FF"
          />
          <path
            d="M7.97016 8.07444V1.44524C7.97016 1.21345 8.06224 0.99116 8.22614 0.827263C8.39004 0.663366 8.61233 0.571289 8.84411 0.571289C9.0759 0.571289 9.29819 0.663366 9.46209 0.827263C9.62599 0.99116 9.71806 1.21345 9.71806 1.44524V8.07444L12.0136 5.78003C12.0947 5.69893 12.191 5.63459 12.297 5.5907C12.403 5.54681 12.5165 5.52421 12.6312 5.52421C12.7459 5.52421 12.8595 5.54681 12.9655 5.5907C13.0714 5.63459 13.1677 5.69893 13.2488 5.78003C13.3299 5.86113 13.3943 5.95742 13.4381 6.06338C13.482 6.16935 13.5046 6.28292 13.5046 6.39762C13.5046 6.51232 13.482 6.62589 13.4381 6.73186C13.3943 6.83782 13.3299 6.93411 13.2488 7.01521L9.4617 10.8023C9.38064 10.8835 9.28437 10.9479 9.1784 10.9918C9.07243 11.0358 8.95883 11.0584 8.84411 11.0584C8.72939 11.0584 8.6158 11.0358 8.50983 10.9918C8.40385 10.9479 8.30758 10.8835 8.22652 10.8023L4.43941 7.01521C4.3583 6.93411 4.29397 6.83782 4.25008 6.73186C4.20619 6.62589 4.18359 6.51232 4.18359 6.39762C4.18359 6.28292 4.20619 6.16935 4.25008 6.06338C4.29397 5.95742 4.3583 5.86113 4.43941 5.78003C4.52051 5.69893 4.6168 5.63459 4.72276 5.5907C4.82873 5.54681 4.9423 5.52421 5.057 5.52421C5.1717 5.52421 5.28527 5.54681 5.39124 5.5907C5.4972 5.63459 5.59349 5.69893 5.67459 5.78003L7.97016 8.07444Z"
            fill="#5B93FF"
          />
        </svg>
   
      <span className="text-[#5B93FF] font-opensans font-semibold text-sm">{text}</span>
        {/* <img src={DownloadIcon} alt="/downloadicon" /> */}
      </button>

      <div className="relative p-2 bg-[#E8E8E8] h-full">
        <span
          className={`text-quotetext-200 arrow ${
            open ? "rotate-[316deg]" : "rotate-[135deg]"
          }  transition-all duration-200 ease-linear`}
        ></span>
        {open && (
          <ul className="absolute z-10 text-[12px] text-[#00173A] font-primary font-normal right-0 top-8 bg-white p-4 w-max flex flex-col gap-4">
            {downloadOutputFormat.map((list, i) => (
              <li
                className="flex items-center gap-2 cursor-pointer"
                onClick={() => handleFormatSelect(list)}
                key={i}
              >
                <p className="w-full">{list}</p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="9"
                  height="14"
                  viewBox="0 0 9 14"
                  fill="none"
                >
                  <path
                    d="M4.5 10V1.5M1 7L4.5 10.5L8 7M1 12.5H8"
                    stroke="#5B93FF"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </li>
            ))}
          </ul>
        )}
      </div>
      <Tooltip
        id="download-tooltip"
        place="top"
        multiline={true}
        className="z-50"
      />
    </div>
  );
};

export default DownloadSection;
